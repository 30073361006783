<template>
  <div class="my-5">
    <div v-if="!isBackOfficeAdmin">You'll only see the cards that are requested/created by you.</div>
    <div class="mb-4" v-for="item in Object.keys(giftCardList)" :key="item">
      <v-data-table
        :headers="getHeader(item)"
        :items="giftCardList[item]"
        :loading="loading"
        class="elevation-1 rounded-lg"
        :single-select="false"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100],
        }">
        <template v-slot:top>
          <v-toolbar
            dark
            flat
            class="rounded-t-lg"
            :style="{
              background: item === 'pending' ? '#f8b559' : item === 'completed' ? '#86c589' : '#f37a62',
            }">
            <div class="d-flex justify-space-between align-center" style="width: 100%">
              <!-- Pending / Approved / Rejected Headers -->
              <h4>
                {{ (item === "completed" ? "approved" : item === "cancelled" ? "rejected" : item).toUpperCase() }}
                CARDS
              </h4>

              <!-- "Request Special Card" -->
              <v-dialog v-if="item === 'pending'" max-width="700px" v-model="dialog">
                <!-- "Request Special Card" Button -->
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="accent" light elevation="0" v-bind="attrs" v-on="on" label="yolo">
                    <v-icon>mdi-plus</v-icon>
                    Request {{ specialCardType.title }}
                  </v-btn>
                </template>
                <!-- "Request Special Card" Window -->
                <v-card>
                  <!-- "Request Special Card" Window Title -->
                  <v-toolbar fixed dark color="secondary">Create {{ specialCardType.title }} </v-toolbar>
                  <!-- "Request Special Card" Window Body -->
                  <v-card-text>
                    <v-container class="mt-5">
                      <ValidationObserver ref="createSpecialCardObserver" v-slot="{ Validate, reset }">
                        <v-row>
                          <v-col>
                            <ValidationProvider v-slot="{ errors }" name="referencedOrderNumbers" rules="required">
                              <v-text-field
                                label="Referenced Order Numbers"
                                outlined
                                :error-messages="errors"
                                v-model="createGiftCardBody.internalReferencedOrderNumbers"></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col>
                            <ValidationProvider v-slot="{ errors }" name="amount" rules="required">
                              <v-text-field
                                label="Amount"
                                outlined
                                type="number"
                                :error-messages="errors"
                                v-model="createGiftCardBody.amount"
                                v-on:change="
                                  createGiftCardBody.amount = parseFloat(createGiftCardBody.amount)
                                "></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col>
                            <v-select
                              outlined
                              class="align-stretch font-weight-bold"
                              v-model="createGiftCardBody.currency"
                              :items="currencies"
                              item-text="label"
                              :menu-props="{ maxHeight: '400' }"
                              label="Select Currency"
                              :persistent-hint="false"
                              hide-details></v-select>
                          </v-col>
                        </v-row>
                        <ValidationProvider v-slot="{ errors }" name="description" rules="required">
                          <v-textarea
                            outlined
                            label="Description"
                            hide-details
                            clearable
                            required
                            :error-messages="errors"
                            v-model="createGiftCardBody.internalDescription" />
                        </ValidationProvider>
                      </ValidationObserver>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="close"> Cancel</v-btn>
                    <v-btn :loading="saveLoader" color="accent" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-toolbar>
        </template>

        <template v-slot:[`item.created`]="{ item }">
          <div class="dateTime">
            <dateTimeField :date="item.created" type="dateTime"></dateTimeField>
          </div>
        </template>

        <template v-slot:[`item.amount`]="{ item }"> {{ item.amount }} {{ item.currency }} </template>

        <template v-slot:[`item.internalReferencedOrderNumbers`]="{ item }">
          <a
            v-if="item.internalReferencedOrderNumbers"
            style="text-decoration: none"
            target="_blank"
            :href="`/orders/search?page=1&itemsPerPage=30&mustSort=false&multiSort=false&query=${item.internalReferencedOrderNumbers}&dates&printable=false&paid=false`">
            <v-chip color="secondary" class="font-weight-bold" style="min-width: 130px" link small>
              {{ item.internalReferencedOrderNumbers }}
            </v-chip>
          </a>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div v-if="item.status === 'pending'">
            <v-btn
              :disabled="item.isCompleted"
              @click="approveOrRejectGiftCard(item.code, true)"
              small
              dark
              color="greeny">
              Approve
              <!-- <v-icon color="success">mdi-check-decagram-outline</v-icon> -->
            </v-btn>
            <v-btn
              :disabled="item.isCompleted"
              @click="approveOrRejectGiftCard(item.code, false)"
              small
              dark
              color="primary"
              class="ml-2">
              Reject
            </v-btn>
          </div>
          <div v-if="item.status === 'completed'">
            <GiftCardPdf :giftCardCode="item.code" />
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import TrainplanetApi from "@/util/trainplanet.api";
import { mapGetters } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import dateTimeField from "@/components/common/dateTimeField.vue";
import GiftCardPdf from "@/components/order/giftCard/GiftCardPdf.vue";

class GiftCardType {
  constructor(title, backendRepresentation) {
    this.title = title;
    this.backendRepresentation = backendRepresentation;
  }
}

class GiftCardStatus {
  constructor(title, backendRepresentation, slug) {
    this.title = title;
    this.backendRepresentation = backendRepresentation;
    this.slug = slug ?? backendRepresentation;
  }
}

export const GiftCardTypes = {
  COMPENSATION_CARD: new GiftCardType("Compensation Card", "compensation-card"),
  STAFF_CARD: new GiftCardType("Staff Card", "staff-card"),
};

export const GiftCardStatuses = {
  PENDING: new GiftCardStatus("Pending", "pending"),
  COMPLETED: new GiftCardStatus("Completed", "completed"),
  CANCELLED: new GiftCardStatus("Cancelled", "cancelled"),
};

export default {
  props: {
    specialCardType: {
      type: GiftCardType,
      default: GiftCardTypes.COMPENSATION_CARD,
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    saveLoader: false,
    giftCardList: {
      [GiftCardStatuses.PENDING.slug]: [],
      [GiftCardStatuses.COMPLETED.slug]: [],
      [GiftCardStatuses.CANCELLED.slug]: [],
    },
    currencies: [],
    createGiftCardBody: {
      amount: 1,
      currency: "SEK",
      internalDescription: "",
      internalReferencedOrderNumbers: "",
      type: GiftCardTypes.COMPENSATION_CARD,
    },
  }),
  components: {
    dateTimeField,
    ValidationProvider,
    ValidationObserver,
    GiftCardPdf,
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    ...mapGetters({
      isBackOfficeAdmin: "auth/isBackOfficeAdmin",
      isSuperAdmin: "auth/isSuperAdmin",
    }),
    headers() {
      const ret = [
        {
          text: "Card Code",
          value: "code",
          align: "start",
        },
        {
          text: "Amount",
          value: "amount",
          align: "start",
        },
        {
          text: "Referenced Order Numbers",
          value: "internalReferencedOrderNumbers",
          align: "start",
        },
        {
          text: "Description",
          value: "internalDescription",
          align: "start",
        },
        {
          text: "Request Date",
          value: "created",
        },
        {
          text: "Requested By",
          value: "createdBy.fullName",
        },
        {
          text: "Approved By",
          value: "approvedBy.fullName",
        },
      ];

      if (this.isSuperAdmin) {
        ret.push({
          text: "Actions",
          value: "actions",
          align: "end",
        });
      }

      return ret;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Gift Card" : "Edit Gift Card";
    },
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("loading", true);

    await Promise.all([
      ...Object.values(GiftCardStatuses).map((r) => this.getAndSetSpecialGiftCards(r)),
      this.getAndSetAvailableCurrencies(),
    ]).finally(() => {
      this.loading = false;
      this.$store.dispatch("loading", false);
    });
  },
  methods: {
    /**
     *
     * @param {string} status_slug
     * @returns {Array}
     */
    getHeader(status_slug) {
      switch (status_slug) {
        case GiftCardStatuses.PENDING.slug:
          return this.headers.filter((x) => x.value !== "approvedBy.fullName");
        case GiftCardStatuses.COMPLETED.slug:
          return this.headers;
        case GiftCardStatuses.CANCELLED.slug:
          return this.headers.filter((x) => x.value !== "actions" && x.value !== "approvedBy.fullName");
        default:
          return this.headers;
      }
    },
    async approveOrRejectGiftCard(id, isApprove) {
      this.$confirm(
        isApprove
          ? `Are you sure you want to approve this ${this.specialCardType.title}?`
          : `Are you sure you want to reject this ${this.specialCardType.title}?`,
        {
          color: isApprove ? "greeny" : "primary",
          title: "Gift Card",
        }
      ).then(async (res) => {
        if (!res) return;
        try {
          await this.$store.dispatch("loading", true);
          this.loading = true;

          if (isApprove) {
            await TrainplanetApi.approveSpecialGiftCard(id);
            await Promise.all([
              this.getAndSetSpecialGiftCards(GiftCardStatuses.PENDING),
              this.getAndSetSpecialGiftCards(GiftCardStatuses.COMPLETED),
            ]);
          } else {
            await TrainplanetApi.cancelSpecialGiftCard(id);
            await Promise.all([
              this.getAndSetSpecialGiftCards(GiftCardStatuses.PENDING),
              this.getAndSetSpecialGiftCards(GiftCardStatuses.CANCELLED),
            ]);
          }

          await this.$store.dispatch(
            "success",
            isApprove
              ? "You've successfully approved this compensation card."
              : "You've rejected this compensation card."
          );
        } catch (error) {
          await this.$store.dispatch("error", error.response.data.message || error.message || error);
        } finally {
          await this.$store.dispatch("loading", false);
          this.loading = false;
        }
      });
    },
    async getAndSetAvailableCurrencies() {
      try {
        const { currencies } = await TrainplanetApi.getOrderSystemOptions();
        this.currencies = Object.keys(currencies).map((key) => ({
          value: key,
          label: currencies[key],
        }));
      } catch (err) {
        await this.$store.dispatch("error", error.response.data.message || error.message || error);
      }
    },
    /**
     *
     * @param {GiftCardStatus} status
     * @returns {Promise<void>}
     */
    async getAndSetSpecialGiftCards(status) {
      try {
        this.giftCardList[status.slug] = await TrainplanetApi.listSpecialGiftCards({
          type: this.specialCardType.backendRepresentation,
          status: status.backendRepresentation,
        });
      } catch (err) {
        await this.$store.dispatch("error", error.response.data.message || error.message || error);
      }
    },
    async save() {
      this.$refs.createSpecialCardObserver[0].validate().then(async (res) => {
        if (!res) {
          return await this.$store.dispatch("error", "You have to reference order numbers and enter a description.");
        }

        this.saveLoader = true;

        this.createGiftCardBody.type = this.specialCardType.backendRepresentation;

        try {
          await TrainplanetApi.createSpecialGiftCard(this.createGiftCardBody);
          await this.getAndSetSpecialGiftCards(GiftCardStatuses.PENDING);

          this.createGiftCardBody = {
            amount: 1,
            currency: "SEK",
            internalDescription: "",
            type: this.specialCardType.backendRepresentation,
          };

          await this.$store.dispatch(
            "success",
            "You've successfully created a compensation card. Wait for an admin to approve it."
          );
        } catch (error) {
          await this.$store.dispatch("error", error.response.data.message || error.message || error);
        } finally {
          this.saveLoader = false;
          await this.$store.dispatch("loading", false);
          this.close();
        }
      });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 1px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(138, 138, 138) !important;
}

.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}

.v-dialog > .v-card > .v-card__actions {
  background: white;
  position: sticky;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  bottom: 0;
  z-index: 999;
}
</style>
