var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-inline-block"
  }, [_c('v-tooltip', {
    attrs: {
      "disabled": !_vm.disabled,
      "bottom": "",
      "color": "black"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [_c('v-btn', {
          attrs: {
            "disabled": _vm.disabled,
            "color": "accent white--text",
            "small": ""
          },
          on: {
            "click": function ($event) {
              _vm.dialog = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-file-pdf-box")]), _c('span', [_vm._v("VIEW OR SEND PDF")])], 1)], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.tooltipMessage))])]), _c('v-dialog', {
    attrs: {
      "persistent": _vm.loadingSend || _vm.loadingViewPdf,
      "max-width": "600px"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline",
    staticStyle: {
      "background-color": "#f37a62"
    }
  }, [_c('span', {
    staticClass: "white--text"
  }, [_vm._v(" View or Send PDF for "), _c('b', [_vm._v(_vm._s(_vm.giftCardCode))])])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', {
    staticClass: "mt-4"
  }, [_vm._v(" You can directly view the PDF in the selected language and tenant theme. To send it via email, please provide an email address. ")]), _c('div', {
    staticClass: "input-container"
  }, [_c('v-text-field', {
    staticStyle: {
      "flex": "1"
    },
    attrs: {
      "rules": [_vm.rules.requiredAndEmail],
      "label": "Email",
      "outlined": "",
      "clearable": "",
      "dense": ""
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _c('v-select', {
    staticStyle: {
      "flex": "1"
    },
    attrs: {
      "items": _vm.languages,
      "item-text": "text",
      "item-value": "value",
      "label": "Select Language",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.selectedLanguage,
      callback: function ($$v) {
        _vm.selectedLanguage = $$v;
      },
      expression: "selectedLanguage"
    }
  }), _c('v-select', {
    staticStyle: {
      "flex": "1"
    },
    attrs: {
      "items": _vm.filteredTenants,
      "item-text": "name",
      "item-value": "id",
      "label": "Select Tenant",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.selectedTenant,
      callback: function ($$v) {
        _vm.selectedTenant = $$v;
      },
      expression: "selectedTenant"
    }
  })], 1)])], 1)], 1), _c('v-card-actions', {
    staticClass: "pb-3"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("Close")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "loading": _vm.loadingViewPdf,
      "color": "accent"
    },
    on: {
      "click": function ($event) {
        return _vm.sendOrGetPdf(true);
      }
    }
  }, [_vm._v("View PDF")]), _c('v-tooltip', {
    attrs: {
      "disabled": _vm.isEmailValid,
      "color": "black",
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('div', _vm._g(_vm._b({
          staticClass: "ml-2"
        }, 'div', attrs, false), on), [_c('v-btn', {
          attrs: {
            "disabled": !_vm.isEmailValid,
            "loading": _vm.loadingSend,
            "color": "success"
          },
          on: {
            "click": function ($event) {
              return _vm.sendOrGetPdf();
            }
          }
        }, [_vm._v(" Send ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Please enter a valid email to send")])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }