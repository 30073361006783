<template>
  <div class="d-inline-block">
    <v-tooltip :disabled="!disabled" bottom color="black">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn @click="dialog = true" :disabled="disabled" color="accent white--text" small>
            <v-icon left>mdi-file-pdf-box</v-icon>
            <span>VIEW OR SEND PDF</span>
          </v-btn>
        </div>
      </template>
      <span>{{ tooltipMessage }}</span>
    </v-tooltip>
    <v-dialog v-model="dialog" :persistent="loadingSend || loadingViewPdf" max-width="600px">
      <v-card>
        <v-card-title class="headline" style="background-color: #f37a62">
          <span class="white--text">
            View or Send PDF for <b>{{ giftCardCode }}</b>
          </span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="mt-4">
                You can directly view the PDF in the selected language and tenant theme. To send it via email, please
                provide an email address.
              </p>
              <div class="input-container">
                <v-text-field
                  v-model="email"
                  :rules="[rules.requiredAndEmail]"
                  style="flex: 1"
                  label="Email"
                  outlined
                  clearable
                  dense>
                </v-text-field>
                <v-select
                  v-model="selectedLanguage"
                  :items="languages"
                  item-text="text"
                  item-value="value"
                  style="flex: 1"
                  label="Select Language"
                  outlined
                  dense></v-select>
                <v-select
                  v-model="selectedTenant"
                  :items="filteredTenants"
                  item-text="name"
                  item-value="id"
                  style="flex: 1"
                  label="Select Tenant"
                  outlined
                  dense></v-select>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-btn @click="dialog = false" color="primary" outlined>Close</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="sendOrGetPdf(true)" :loading="loadingViewPdf" color="accent">View PDF</v-btn>
          <v-tooltip :disabled="isEmailValid" color="black" top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on" class="ml-2">
                <v-btn @click="sendOrGetPdf()" :disabled="!isEmailValid" :loading="loadingSend" color="success">
                  Send
                </v-btn>
              </div>
            </template>
            <span>Please enter a valid email to send</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api.js";
import { mapGetters } from "vuex";

export default {
  name: "GiftCardPdf",
  props: {
    giftCardCode: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltipMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rules: {
        requiredAndEmail: (value) => {
          if (!value) return "Email is required.";
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      languages: [
        { text: "English", value: "en-US" },
        { text: "Swedish", value: "sv-SE" },
        { text: "Norwegian", value: "nb-NO" },
      ],
      email: null,
      selectedLanguage: "en-US",
      selectedTenant: "6ef7e19b-69b0-4805-b8a0-81bf7c3ca1d9",
      dialog: false,
      loadingViewPdf: false,
      loadingSend: false,
    };
  },
  watch: {
    email() {
      if (!this.email) this.email = null;
    },
  },
  computed: {
    ...mapGetters({
      tenants: "auth/userTenants",
    }),
    isEmailValid() {
      return this.rules.requiredAndEmail(this.email) === true;
    },
    filteredTenants() {
      return this.tenants.filter((tenant) => tenant.canUseGiftCards);
    },
  },
  methods: {
    async sendOrGetPdf(getPdfLink = false) {
      if (getPdfLink) {
        this.loadingViewPdf = true;
      } else {
        this.loadingSend = true;
      }

      try {
        const res = await Trainplanet.sendGiftCard(
          this.giftCardCode,
          { email: this.email },
          this.selectedLanguage,
          this.selectedTenant,
          getPdfLink
        );

        if (getPdfLink) {
          window.open(res.accessUrl, "_blank");
        } else {
          this.$store.dispatch("success", "Gift card sent successfully.");
          this.dialog = false;
        }
      } catch (error) {
        this.$store.dispatch("error", error.response.data.message || error.message);
      } finally {
        if (getPdfLink) {
          this.loadingViewPdf = false;
        } else {
          this.loadingSend = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  display: flex;
  gap: 10px;
}
</style>
