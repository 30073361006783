var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "grey--text text--darken-4 font-weight-medium one-line",
    staticStyle: {
      "white-space": "nowrap"
    }
  }, [_vm.type == 'date' || _vm.type == 'dateTime' ? _c('span', [_vm._v(_vm._s(_vm._f("getOnlyDate")(_vm.date)))]) : _vm._e(), _vm.type == 'time' || _vm.type == 'dateTime' ? _c('span', {
    staticClass: "grey--text text--darken-4 font-weight-regular"
  }, [_c('v-icon', {
    staticClass: "third--text clock"
  }, [_vm._v("mdi-clock-time-eight-outline")]), _vm._v(_vm._s(_vm._f("getOnlyTime")(_vm.date)))], 1) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }