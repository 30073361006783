<template>
 <!-- <dateTimeField :date="item.created" type="dateTime"></dateTimeField> -->
  <div>
    <div class="grey--text text--darken-4 font-weight-medium one-line" style="white-space: nowrap;">
      <span  v-if="type == 'date' || type == 'dateTime'">{{ date | getOnlyDate }}</span>
      <span
        class="grey--text text--darken-4 font-weight-regular"
        v-if="type == 'time' || type == 'dateTime'"
      >
        <v-icon class="third--text clock">mdi-clock-time-eight-outline</v-icon
        >{{ date | getOnlyTime }}</span
      >
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "dateTimeField",
  props: {
    date: String,
    type: String,
  },
  filters: {
    humanDatetime(datetime) {
      return moment.utc(datetime).local().fromNow();
    },
    getOnlyDate(datetime) {
      return moment.utc(datetime).local().format("YYYY-MM-DD");
    },
    getOnlyTime(datetime) {
      return moment.utc(datetime).local().format("HH:mm");
    },
  },
};
</script>
<style scoped>
.clock {
  font-size: 15px !important;
  margin-top: -1px !important;
  margin-left: 5px !important;
  margin-right: 2px !important;
}
</style>
